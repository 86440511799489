import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { ROUTE_GROUPS, ROUTE_HELP, ROUTE_SHORTCUTS, ROUTE_SUBSTITUTES, ROUTE_TASKS } from '../../constants'

const useMainNavSelectionPaths = () => {
  const location = useLocation()

  return useMemo(
    () => ({
      isShortcutsPath: location?.pathname === '/' || location?.pathname?.startsWith(ROUTE_SHORTCUTS),
      isTasksPath: location?.pathname?.startsWith(ROUTE_TASKS),
      isGroupsPath: location?.pathname?.startsWith(ROUTE_GROUPS),
      isSubstitutesPath: location?.pathname?.startsWith(ROUTE_SUBSTITUTES),
      isHelpPath: location?.pathname?.startsWith(ROUTE_HELP),
    }),
    [location?.pathname]
  )
}

export default useMainNavSelectionPaths
