import { getLocalStorage, setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { OPTION_ALL_GROUPS } from '../../../../components/TasksSidebar/constants'
import { QUERY_TASKSV2_OVERVIEW_SIDEBAR, ROUTE_TASKS_ADD, TASKV2_STATUS_DONE } from '../../../../constants'
import { TasksSidebarContext } from '../../../../context'
import useTasks from '../../../../hooks/tasksV2/useTasks'
import BoxScrollable from '../../../../ui-components/BoxScrollable/BoxScrollable'
import firstSchoolDayOfWeek from '../../../../utils/dateTime/firstSchoolDayOfWeek'
import lastSchoolDayOfWeek from '../../../../utils/dateTime/lastSchoolDayOfWeek'
import TasksSidebarHeader from './Header'
import TaskList from './TaskList'

const TaskSidebar = () => {
  const {
    currentUserIsStudent,
    currentUserId,
    currentInstitutionId,
    useGetQuery,
    getTaskOverviewQueryConfig,
    navigate,
    handleCloseTaskSidebar,
    handleToggleTaskSidebar,
    groupsList,
    useTasksStyles,
    selectedGroupId,
    setSelectedGroupId,
    referenceDate,
    selectedPeriod,
  } = useTasks()
  const { queryName, queryKeys, argumentKey, argumentValue, additionalFields } = getTaskOverviewQueryConfig()
  const { tasksSidebarContent } = useTasksStyles()
  const { firstDay, lastDay } = useMemo(
    () => ({
      firstDay: firstSchoolDayOfWeek(referenceDate),
      lastDay: lastSchoolDayOfWeek(referenceDate),
    }),
    [referenceDate]
  )
  const {
    data: tasks,
    isLoading,
    isSuccess,
    isError: isErrorFetchingData,
  } = useGetQuery({
    isTaskSidebar: true,
    queryKeys: [...queryKeys, `${QUERY_TASKSV2_OVERVIEW_SIDEBAR}-${currentUserId}`],
    queryName,
    queryOptions: {
      staleTime: 1000,
    },
    queryData: `
      query {
        ${queryName}(
          ${argumentKey}: ${argumentValue}
          filters: {
            scheduleDate: {
              from: "${selectedPeriod.firstDay}",
              to: "${selectedPeriod.lastDay}"
            },
          }
        ) {
            id
            scheduleDate
            handInDate
            title
            ${additionalFields}
        }
      }
    `,
  })

  const isError = useMemo(() => tasks?.errors?.length > 0 || isErrorFetchingData, [tasks, isErrorFetchingData])

  const mappedGroupsTask = useMemo(() => {
    if (isError) {
      return
    }

    const filteredTasks = tasks?.filter((task) => {
      if (currentUserIsStudent) {
        return task.status !== TASKV2_STATUS_DONE
      }

      return task.taskProgress.done !== task.taskProgress.total
    })

    const allGroupsData = [
      {
        id: OPTION_ALL_GROUPS,
        tasks: filteredTasks,
      },
    ]

    if (currentUserIsStudent) {
      return allGroupsData
    }

    const groupsData = groupsList?.map((group) => ({
      id: group.id,
      tasks: filteredTasks?.filter((filteredTask) => {
        if (filteredTask.groups.some((groupItem) => groupItem.id === group.id)) {
          return filteredTask
        }

        return null
      }),
    }))

    return groupsData?.concat(allGroupsData)
  }, [isError, groupsList, tasks, currentUserIsStudent])

  const handleGroupChange = useCallback(
    (_, newGroup) => {
      setSelectedGroupId(newGroup.props.value)
      setLocalStorage(`zc#.${currentUserId}.${currentInstitutionId}.filters.selectedGroupId`, newGroup.props.value)
    },
    [currentUserId, currentInstitutionId, setSelectedGroupId]
  )

  const handleCreateTask = useCallback(() => {
    navigate(ROUTE_TASKS_ADD)
    handleCloseTaskSidebar()
  }, [handleCloseTaskSidebar, navigate])

  const updatedTasks = useMemo(() => {
    if (isError) {
      return {
        errors: tasks?.errors,
      }
    }

    if (mappedGroupsTask) {
      return mappedGroupsTask.find((group) => group.id === selectedGroupId)?.tasks
    }

    return []
  }, [isError, tasks, mappedGroupsTask, selectedGroupId])

  const sidebarContext = {
    selectedGroupId,
    handleGroupChange,
    handleToggleTaskSidebar,
    isLoading,
    isSuccess,
    isError,
    groupsList,
    currentUserIsStudent,
    updatedTasks,
  }

  useEffect(() => {
    if (!currentUserIsStudent) {
      setSelectedGroupId(
        getLocalStorage(`zc#.${currentUserId}.${currentInstitutionId}.filters.selectedGroupId`) ?? OPTION_ALL_GROUPS
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TasksSidebarContext.Provider value={sidebarContext}>
      <Box data-analytics="TaskSidebarContainer">
        <Box
          data-analytics="tasksSidebarHeader"
          sx={(theme) => ({
            padding: theme.spacing(3),
          })}
        >
          <TasksSidebarHeader firstDay={firstDay} lastDay={lastDay} onClickCreateTask={handleCreateTask} />
        </Box>
        <BoxScrollable>
          <Box sx={tasksSidebarContent} role="main">
            <TaskList onClickCreateTask={handleCreateTask} />
          </Box>
        </BoxScrollable>
      </Box>
    </TasksSidebarContext.Provider>
  )
}

TasksSidebarContext.Provider.propTypes = {
  value: PropTypes.shape({
    selectedGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleGroupChange: PropTypes.func,
    handleToggleTaskSidebar: PropTypes.func,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    groupsList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        image_url: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    currentUserIsStudent: PropTypes.bool,
    updatedTasks: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          baseTaskId: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
            })
          ),
          handInDate: PropTypes.string,
          id: PropTypes.string,
          rrule: PropTypes.string,
          scheduleDate: PropTypes.string,
          taskProgress: PropTypes.shape({
            done: PropTypes.number,
            in_progress: PropTypes.number,
            open: PropTypes.number,
            total: PropTypes.number,
          }),
          title: PropTypes.string,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
            })
          ),
        })
      ),
      PropTypes.shape({
        errors: PropTypes.array,
      }),
    ]),
  }),
}

export default TaskSidebar
