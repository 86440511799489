import useStateToggle from '@derolfgroep/utils/lib/react/hooks/useStateToggle'
import { alpha, useTheme } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { endOfDay, endOfMonth, formatISO, startOfDay, startOfMonth } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useMemo, useRef, useState } from 'react'
import { Link, Navigate, generatePath, useLocation, useNavigate } from 'react-router'
import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import request from '../../api/request'
import useToggleRightDrawer from '../../components/AppLayout/useToggleRightDrawer'
import { OPTION_ALL_GROUPS } from '../../components/TasksSidebar/constants'
import { useSelectableData } from '../utilities/useSelectableData'

import useQueryData from '../../api/useQueryData'
import {
  DEFAULT,
  ERROR,
  POST,
  QUERY_SELECTABLE_TASK_GROUPLIST,
  QUERY_TASKSV2_DETAIL,
  QUERY_TASKSV2_OVERVIEW,
  QUERY_TASKSV2_STUDENTDETAIL,
  QUERY_TASKSV2_STUDENTOVERVIEW,
  ROLE_STUDENT,
  ROUTE_TASKS,
  SELECTABLES_TASKS_GROUP_FILTER,
  SNACKBAR_TASK_COPY,
  SNACKBAR_TASK_CREATE,
  SNACKBAR_TASK_DELETE,
  SNACKBAR_TASK_DONE,
  SNACKBAR_TASK_EDIT,
  SNACKBAR_TASK_START,
  SUCCESS,
  TASKS_LIST_LAYOUT_TILES,
  TASKS_PERIOD_DAY,
  TASKS_PERIOD_MONTH,
  TASKS_PERIOD_WEEK,
  TASKS_RECURRENCE_NONE,
  TASKS_RECURRENCE_WEEKDAYS_MONDAY,
  TASKS_RRULE_PREFIX,
  TASKV2_STATUS_DONE,
  TASKV2_STATUS_IN_PROGRESS,
  TASKV2_STATUS_OPEN,
} from '../../constants'
import firstSchoolDayOfWeek from '../../utils/dateTime/firstSchoolDayOfWeek'
import lastSchoolDayOfWeek from '../../utils/dateTime/lastSchoolDayOfWeek'

const useTasks = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { currentSchool, isStudent: currentUserIsStudent, currentUserId } = useCurrentUser()
  const {
    isOpen: isOpenContextMenu,
    handleOpen: handleOpenContextMenu,
    handleClose: handleCloseContextMenu,
  } = useStateToggle()
  const contextMenuRef = useRef()
  const isOpenContextMenuTableView = useRef(null)
  const requestConfig = {
    baseURL: process.env.TASK_SERVICE_URL,
    endpoint: '/tasks/',
    method: POST,
  }
  const snackBarConfig = (type) =>
    ({
      [SNACKBAR_TASK_CREATE]: {
        success: 'De taak is aangemaakt',
        error: 'Fout bij het maken van de taak',
      },
      [SNACKBAR_TASK_DELETE]: {
        success: 'De taak is verwijderd',
        error: 'Fout bij het verwijderen van de taak',
      },
      [SNACKBAR_TASK_EDIT]: {
        success: 'De taak is bijgewerkt',
        error: 'Fout bij het bewerken van de taak',
      },
      [SNACKBAR_TASK_COPY]: {
        success: 'De taak is gekopieerd',
        error: 'Fout bij het kopiëren van de taak',
      },
      [SNACKBAR_TASK_START]: {
        success: 'De taak is gestart',
        error: 'Fout bij het starten van de taak',
      },
      [SNACKBAR_TASK_DONE]: {
        success: 'De taak is afgerond',
        error: 'Fout bij het afronden van de taak',
      },
    })[type.toLowerCase()]
  const location = useLocation()

  const useGetQuery = ({
    queryKeys,
    queryName,
    queryData,
    isTaskDetail = false,
    queryOptions = {},
    operationName = null,
    filterGroupId = null,
    filterByPeriod = {},
    filterByStatus = null,
    filterBySubject = null,
    isTaskSidebar = false,
  }) =>
    useQueryData({
      queryKey: [...queryKeys],
      queryFn: async () => {
        const { data, errors } = await request({
          ...requestConfig,
          data: JSON.stringify({
            filterGroupId,
            filterByPeriod,
            filterByStatus,
            filterBySubject,
            operation: operationName,
            query: queryData,
          }),
        })

        if (!errors) {
          return data[queryName]
        }

        // Workaround to handle known backend issue where it partially returns null and tasks in certain queries.
        // This block will remove those null and only return all tasks
        // TODO: Remove line 121-129 if BE is fixed. BE shouldn't return partial null and tasks in an array.
        const isNullInQueryResponseArray =
          (Array.isArray(data[queryName]) && data[queryName].some((item) => Boolean(item))) ||
          Array.isArray(data[queryName])
        if (isNullInQueryResponseArray) {
          return data[queryName].filter(Boolean)
        }

        return { data, errors }
      },
      queryOptions: {
        retry: 1,
        ...queryOptions,
      },
      onSuccess: (data) => {
        if (!data?.errors) {
          return
        }

        // eslint-disable-next-line no-console
        console.error('GRAPHQL ERROR: ', data?.errors)

        if (data && !data[queryName]) {
          enqueueSnackbar('De taak bestaat niet voor deze instelling', { variant: ERROR })

          const isTasksHome = location.pathname === ROUTE_TASKS
          if (isTaskSidebar || isTasksHome) {
            return
          }

          return navigate(ROUTE_TASKS)
        }

        if (!isTaskDetail) {
          return
        }

        enqueueSnackbar('Fout bij het ophalen van taakdetail', { variant: ERROR })
        navigate(ROUTE_TASKS)
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error('NETWORK ERROR: ', error)

        if (!isTaskDetail) {
          return
        }

        enqueueSnackbar('Fout bij het ophalen van taakdetail', { variant: ERROR })
        navigate(ROUTE_TASKS)
      },
    })

  const useMutationTasks = ({ onSuccess = null, snackBarType = SNACKBAR_TASK_CREATE, invalidateQueries = [] }) =>
    useMutation({
      mutationFn: async (data) => await request({ ...requestConfig, data }),
      onSuccess: async (data) => {
        if (onSuccess) {
          onSuccess()
        }

        if (data?.errors) {
          enqueueSnackbar(snackBarConfig(snackBarType).error, { variant: ERROR })

          // eslint-disable-next-line no-console
          console.error('RESPONSE ERROR: ', data?.errors)
          return
        }

        await queryClient.invalidateQueries({
          queryKey: [...invalidateQueries],
        })
        enqueueSnackbar(snackBarConfig(snackBarType).success, { variant: SUCCESS })
      },
      onError: (error) => {
        enqueueSnackbar(snackBarConfig(snackBarType).error, { variant: ERROR })

        // eslint-disable-next-line no-console
        console.error('NETWORK ERROR: ', error)
      },
    })

  const useGetTaskRoleProps = ({ done = 0, total = 0, onClickContext = handleOpenContextMenu, status }) => {
    if (currentUserIsStudent) {
      return {
        status: status?.toLowerCase(),
      }
    }

    return {
      completion: `${done} van de ${total} ${total === 1 ? 'leerling' : 'leerlingen'}`,
      onClickContext,
    }
  }

  const getAssigneeList = (nodeType, nodes) => nodes.filter((node) => node.type === nodeType).map((item) => item.id)

  const getFormattedDateTime = (date, disableTimezoneOffset = false) => {
    if (disableTimezoneOffset) {
      return formatISO(date.setHours(date.getHours() / 60))
    }

    return formatISO(date.setHours(date.getHours() + date.getTimezoneOffset() / 60))
  }

  const role = currentUserIsStudent ? ROLE_STUDENT : DEFAULT
  const getTaskDetailQuery = (id) =>
    ({
      [ROLE_STUDENT]: `
        query {
          ${QUERY_TASKSV2_STUDENTDETAIL} (
            userId: ${currentUserId}
            progressId: "${id}",
          ) {
            id
            title
            description
            scheduleDate
            handInDate
            link
            status
              section {
                uniqueId
                title
                subject {
                    uniqueId
                    title
                }
              }
          }
        }
      `,
      [DEFAULT]: `
        query {
          ${QUERY_TASKSV2_DETAIL} (
            taskId: "${id}",
            institutionId: ${currentSchool.id}
          ) {
            id
            baseTaskId
            section {
              title
              uniqueId
              id
              subject {
                title
                uniqueId
                id
              }
            }
            link
            description
            scheduleDate
            handInDate
            title
            rrule
            users {
              id
            }
            groups {
              id
            }
            taskProgress {
              open
              in_progress
              done
              total
            }
            userProgress {
              userId
              status
            }
          }
        }`,
    })[role]

  const getTaskOverviewQueryConfig = () =>
    ({
      [ROLE_STUDENT]: {
        queryName: QUERY_TASKSV2_STUDENTOVERVIEW,
        queryKeys: [QUERY_TASKSV2_STUDENTOVERVIEW],
        argumentKey: 'userId',
        argumentValue: currentUserId,
        additionalFields: 'status',
      },
      [DEFAULT]: {
        queryName: QUERY_TASKSV2_OVERVIEW,
        queryKeys: [QUERY_TASKSV2_OVERVIEW],
        argumentKey: 'institutionId',
        argumentValue: currentSchool.id,
        additionalFields: `
          baseTaskId
            taskProgress {
              done
              total
              in_progress
              open
            }
          groups {
            id
          }
          users {
            id
          }
          rrule`,
      },
    })[role]

  const useTasksStyles = () => {
    const theme = useTheme()

    return useMemo(
      () => ({
        tasksSidebarContent: {
          paddingLeft: `clamp(${theme.spacing(1)}, ${theme?.custom?.app.scaleFactor.default}, ${theme.spacing(2)})`,
          paddingRight: `clamp(${theme.spacing(1)}, ${theme?.custom?.app.scaleFactor.default}, ${theme.spacing(2)})`,
        },
        boxSearch: {
          color: theme.palette.primary.main,
          border: `1px solid transparent`,
          borderRadius: theme.borderRadius.xl,
          backgroundColor: theme.palette.common.white,
          position: 'relative',
          width: '100%',
          transition: theme.transitions.create(['background-color', 'box-shadow'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),
          '&:hover, &:focus-within': {
            backgroundColor: theme.palette.common.white,
          },
          '&:focus-within': {
            boxShadow: theme.custom.app.input.focus.boxShadow,
          },
        },
        boxSearchContrasting: {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          '&:hover, &:focus-within': {
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
          },
        },
        boxSearchIcon: {
          color: 'inherit',
          padding: theme.spacing(0, 1),
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        boxSearchInputRoot: {
          color: 'inherit',
          fontWeight: theme.typography.fontWeightMedium,
          width: '100%',
        },
        boxSearchInputInput: {
          paddingLeft: theme.spacing(5),
          paddingRight: theme.spacing(1),
          width: 'inherit',
          textOverflow: 'ellipsis',
        },
        taskDetailProgress: {
          table: {
            container: {
              padding: theme.spacing(0, 0.5),
              width: '100%',
            },
            innerContainer: {
              maxHeight: theme.spacing(44),
              scrollbarWidth: 'none',
            },
            row: {
              head: {
                display: 'table-row',
                outline: `${theme.typography.pxToRem(1)} solid ${alpha(theme.palette.common.gray, 0.22)}`,
                '& .MuiTableCell-head': {
                  color: alpha(theme.palette.common.black, 0.65),
                  fontWeight: 'initial',
                  '&:first-of-type': {
                    paddingLeft: 0,
                  },
                },
              },
              body: {
                '& .MuiTableCell-body': {
                  '&:first-of-type': {
                    paddingLeft: 0,
                  },
                  '&:last-of-type': {
                    paddingRight: 0,
                  },
                },
              },
            },
          },
          avatar: {
            main: {
              marginRight: theme.spacing(2),
              width: theme.spacing(5.5),
              height: theme.spacing(5.5),
            },
            fallback: {
              width: theme.spacing(5.5),
              height: theme.spacing(5.5),
            },
          },
        },
      }),
      [theme]
    )
  }

  const getTaskDetailButtonStatusProps = (status = TASKV2_STATUS_OPEN) =>
    ({
      [TASKV2_STATUS_OPEN]: {
        color: 'secondary',
        variant: 'contained',
        icon: 'IconPlayArrowRounded',
        title: 'Taak starten',
      },
      [TASKV2_STATUS_IN_PROGRESS]: {
        color: 'primary',
        variant: 'contained',
        icon: 'IconFlagRounded',
        title: 'Taak inleveren',
      },
      [TASKV2_STATUS_DONE]: {
        disabled: true,
        color: 'secondary',
        variant: 'contained',
        icon: 'IconFlagRounded',
        title: 'Afgerond',
      },
    })[status]

  const taskRruleCustom = useRef(null)
  const counts = useRef(null)
  const [openDialogRecurrenceCustom, setOpenDialogRecurrenceCustom] = useState(false)
  const [taskRecurrence, setTaskRecurrence] = useState(TASKS_RECURRENCE_NONE)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [repeatEvery, setRepeatEvery] = useState(1)
  const [weekdays, setWeekdays] = useState([TASKS_RECURRENCE_WEEKDAYS_MONDAY])
  const taskDataInitial = useRef(null)
  const taskDataUpdated = useRef(null)
  const taskDataOnChange = useRef(null)
  const [isUpdatedDataChanging, setIsUpdatedDataChanging] = useState(false)
  const useIsCustomRrule = (rrule) => {
    const taskRrule = rrule?.split(TASKS_RRULE_PREFIX)[1]
    const isCustomRrule = taskRrule ? (taskRrule.indexOf('BYDAY=') || taskRrule.indexOf('UNTIL=')) > 0 : false
    return { taskRrule, isCustomRrule }
  }
  const resetRecurrenceCustomFields = () => {
    taskRruleCustom.current = null
    setRepeatEvery(1)
    setWeekdays([TASKS_RECURRENCE_WEEKDAYS_MONDAY])
    setSelectedEndDate(null)
    counts.current = 1
    setIsUpdatedDataChanging(false)
  }
  const recurrenceCustomContext = {
    openDialogRecurrenceCustom,
    setOpenDialogRecurrenceCustom,
    taskRruleCustom,
    taskRecurrence,
    setTaskRecurrence,
    selectedEndDate,
    setSelectedEndDate,
    repeatEvery,
    setRepeatEvery,
    weekdays,
    setWeekdays,
    counts,
    resetRecurrenceCustomFields,
    taskDataInitial,
    taskDataUpdated,
    taskDataOnChange,
    isUpdatedDataChanging,
    setIsUpdatedDataChanging,
    useIsCustomRrule,
  }
  const getFinalValueForFormField = (enteredValue, max, min = 1) => {
    if (enteredValue < min) {
      return min
    }

    if (enteredValue > max) {
      return max
    }

    return enteredValue
  }
  const disableCertainDatesOnDatepicker = (date) => {
    // The following is disabling weekends (saturday and sunday) only
    // TODO: Add more date based logic here for additional days to disable like national holidays, etc.
    const results = date.getDay() === 0 || date.getDay() === 6

    return results
  }

  const [referenceDate, setReferenceDate] = useState(new Date())
  const [selectedGroupId, setSelectedGroupId] = useState(OPTION_ALL_GROUPS)
  const {
    handleClose: handleCloseTaskSidebar,
    handleOpen: handleOpenTaskSidebar,
    handleToggle: handleToggleTaskSidebar,
  } = useToggleRightDrawer()
  const { selectables: groupsList } = useSelectableData({
    iterator: SELECTABLES_TASKS_GROUP_FILTER,
    query: QUERY_SELECTABLE_TASK_GROUPLIST,
    enableFetch: !currentUserIsStudent,
    userId: currentUserId,
  })

  const [searchTerm, setSearchTerm] = useState(null)
  const [minCharNotMetWarningMessage, setMinCharNotMetWarningMessage] = useState(null)
  const taskOperationName = useRef(null)

  const [layout, setLayout] = useState(TASKS_LIST_LAYOUT_TILES)
  const getSelectedPeriodData = (period, baseDate = referenceDate) =>
    ({
      [TASKS_PERIOD_DAY]: {
        query: startOfDay(baseDate).toISOString() + baseDate.toISOString() + endOfDay(baseDate).toISOString(),
        firstDay: getFormattedDateTime(startOfDay(baseDate), true),
        lastDay: getFormattedDateTime(endOfDay(baseDate)),
        period: TASKS_PERIOD_DAY,
      },
      [TASKS_PERIOD_WEEK]: {
        query:
          firstSchoolDayOfWeek(baseDate).toISOString() +
          baseDate.toISOString() +
          lastSchoolDayOfWeek(baseDate).toISOString(),
        firstDay: getFormattedDateTime(firstSchoolDayOfWeek(baseDate), true),
        lastDay: getFormattedDateTime(lastSchoolDayOfWeek(baseDate)),
        period: TASKS_PERIOD_WEEK,
      },
      [TASKS_PERIOD_MONTH]: {
        query: startOfMonth(baseDate).toISOString() + baseDate.toISOString() + endOfMonth(baseDate).toISOString(),
        firstDay: getFormattedDateTime(startOfMonth(baseDate), true),
        lastDay: getFormattedDateTime(endOfMonth(baseDate)),
        period: TASKS_PERIOD_MONTH,
      },
    })[period]
  const [selectedPeriod, setSelectedPeriod] = useState(getSelectedPeriodData(TASKS_PERIOD_WEEK))
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT)
  const [selectedSubject, setSelectedSubject] = useState(DEFAULT)
  const [filterTriggered, setFilterTriggered] = useState(false)

  return {
    navigate,
    generatePath,
    currentUserIsStudent,
    currentInstitutionId: currentSchool.id,
    useGetTaskRoleProps,
    enqueueSnackbar,
    Link,
    Navigate,
    useMutationTasks,
    useGetQuery,
    getAssigneeList,
    contextMenuRef,
    isOpenContextMenu,
    isOpenContextMenuTableView,
    handleOpenContextMenu,
    handleCloseContextMenu,
    getFormattedDateTime,
    getTaskDetailQuery,
    getTaskOverviewQueryConfig,
    useTasksStyles,
    getTaskDetailButtonStatusProps,
    role,
    recurrenceCustomContext,
    getFinalValueForFormField,
    disableCertainDatesOnDatepicker,
    handleCloseTaskSidebar,
    handleOpenTaskSidebar,
    handleToggleTaskSidebar,
    groupsList,
    currentUserId,
    selectedGroupId,
    setSelectedGroupId,
    referenceDate,
    setReferenceDate,
    useIsCustomRrule,
    searchTerm,
    setSearchTerm,
    minCharNotMetWarningMessage,
    setMinCharNotMetWarningMessage,
    taskOperationName,
    selectedPeriod,
    setSelectedPeriod,
    layout,
    setLayout,
    getSelectedPeriodData,
    selectedStatus,
    setSelectedStatus,
    selectedSubject,
    setSelectedSubject,
    filterTriggered,
    setFilterTriggered,
  }
}

export default useTasks
