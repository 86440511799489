import { Box, ListItem } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router'
import useCurrentUserIsStudentSelector from '../../api/selectors/userRoleSelectors/useCurrentUserIsStudentSelector'
import {
  ROUTE_TASKS_DETAILS,
  TASK_STATUS_DONE,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_MAP,
  TASK_STATUS_OPEN,
} from '../../constants'
import prettifyCloseDate from '../../utils/dateTime/prettifyCloseDate'
import useToggleRightDrawer from '../AppLayout/useToggleRightDrawer'
import TaskCompact from '../Tasks/TaskCompact'
import TaskLinearProgress from '../Tasks/TaskLinearProgress'

const Task = forwardRef(({ task }, ref) => {
  const { id, title, description, ends_at: endsAt, status } = task
  const navigate = useNavigate()
  const { handleClose } = useToggleRightDrawer()
  const isStudent = useCurrentUserIsStudentSelector()
  const handleClick = useCallback(() => {
    handleClose()
    navigate(generatePath(ROUTE_TASKS_DETAILS, { taskId: id }))
  }, [handleClose, navigate, id])

  return (
    <ListItem disableGutters ref={ref}>
      <TaskCompact
        data-analytics="tasksSidebarTask"
        onClick={handleClick}
        status={isStudent ? TASK_STATUS_MAP?.[status] : ''}
        title={title}
        content={description}
        dueDateTime={prettifyCloseDate(endsAt)}
      >
        {!isStudent && (
          <Box mt={1}>
            <TaskLinearProgress
              studentsNotStartedValue={task.progress[TASK_STATUS_OPEN]}
              studentsStartedValue={task.progress[TASK_STATUS_IN_PROGRESS]}
              studentsDoneValue={task.progress[TASK_STATUS_DONE]}
            />
          </Box>
        )}
      </TaskCompact>
    </ListItem>
  )
})

Task.displayName = 'Task'

Task.propTypes = {
  task: PropTypes.object,
}

export default Task
