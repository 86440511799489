import {
  ACTION_ACTIVATE_TAB,
  ACTION_ALERT_STUDENT_CONNECT,
  ACTION_ALERT_STUDENT_DISCONNECT,
  ACTION_ALERT_STUDENT_OFFLINE,
  ACTION_ALERT_STUDENT_ONLINE,
  ACTION_ALERT_TEACHER_OFFLINE,
  ACTION_BLOCK_ALL_TABS,
  ACTION_CLOSE_TAB,
  ACTION_OBSERVE,
  ACTION_OPEN_TAB,
  ACTION_RELEASE_ALL_BLOCKED_TABS,
  ACTION_SET_DONT_SHOW_BEING_OBSERVED_INDICATOR,
  ACTION_SET_SHOW_BEING_OBSERVED_INDICATOR,
  ACTION_SUBSCRIBE,
} from '../Constants'
import { Client } from './Client.js'
import { HttpTeacher } from './Rest/HttpTeacher.js'
import { debounceCustomResolver } from '../Debounce.js'

export class Teacher extends Client {
  #studentConnections = {}

  /**
   * @type {HttpTeacher}
   */
  rest

  withRest(restBaseUrl) {
    this.rest = new HttpTeacher(restBaseUrl, this.token, this.schoolId)
  }

  /**
   * @param {typeof import('../Websocket.js').Websocket} websocket
   * @param {string} gatewayUrl
   */
  withGateway(websocket, gatewayUrl) {
    super.withGateway(websocket, gatewayUrl)

    const checkConnections = debounceCustomResolver(
      (studentId) => {
        const activeConnections = [
          ...this.#studentConnections[studentId].connects.filter(
            (connectionId) => !this.#studentConnections[studentId].disconnects.includes(connectionId)
          ),
        ]

        this.gateway.emit(activeConnections.length > 0 ? ACTION_ALERT_STUDENT_ONLINE : ACTION_ALERT_STUDENT_OFFLINE, [
          {
            studentId,
          },
        ])
      },
      (studentId) => studentId,
      500
    )

    this.gateway.on(ACTION_ALERT_STUDENT_CONNECT, (message) => {
      if (!this.#studentConnections[message.studentId]) {
        this.#studentConnections[message.studentId] = { connects: [], disconnects: [] }
      }

      this.#studentConnections[message.studentId].connects.push(message.connectionId)

      checkConnections(message.studentId)
    })

    this.gateway.on(ACTION_ALERT_STUDENT_DISCONNECT, (message) => {
      if (!this.#studentConnections[message.studentId]) {
        this.#studentConnections[message.studentId] = { connects: [], disconnects: [] }
      }

      this.#studentConnections[message.studentId].disconnects.push(message.connectionId)

      checkConnections(message.studentId)
    })
  }

  async pollOnlineStudents() {
    const connectionInfo = await (await this.rest.getOnlineStudents()).json()

    for (const studentId in connectionInfo) {
      this.#overwriteStudentConnections(studentId, connectionInfo[studentId])
    }
  }

  /**
   * @param {string} studentId
   * @param {string[]} connections
   */
  #overwriteStudentConnections(studentId, connections) {
    const stored = this.#studentConnections[studentId] ?? { connects: [], disconnects: [] }
    const storedConnects = stored.connects
    const storedDisconnects = stored.disconnects

    const missedConnects = connections.filter((connectionId) => !storedConnects.includes(connectionId))

    const missedDisconnects = storedConnects
      .filter((connectionId) => !connections.includes(connectionId))
      .filter((connectionId) => !storedDisconnects.includes(connectionId))

    for (const connectionId of missedConnects) {
      this.gateway.emit(ACTION_ALERT_STUDENT_CONNECT, [
        {
          studentId,
          connectionId,
        },
      ])
    }

    for (const connectionId of missedDisconnects) {
      this.gateway.emit(ACTION_ALERT_STUDENT_DISCONNECT, [
        {
          studentId,
          connectionId,
        },
      ])
    }
  }

  /**
   * @param {boolean} sendScreenshots
   * @param {boolean} showIndicator
   * @param {string[]} studentIds
   */
  setRemoteViewState(sendScreenshots, showIndicator, studentIds) {
    this.gateway.send({
      action: ACTION_OBSERVE,
      studentIds,
      sendScreenshots,
      showIndicator,
    })
  }

  /**
   * @param {boolean} state
   * @param {string[]} studentIds
   */
  setRemoteViewIndicatorState(showIndicator, studentIds) {
    this.gateway.send({
      action: showIndicator ? ACTION_SET_SHOW_BEING_OBSERVED_INDICATOR : ACTION_SET_DONT_SHOW_BEING_OBSERVED_INDICATOR,
      studentIds,
    })
  }

  /**
   * @param {string} url
   * @param {string[]} studentIds
   * @param {blockOtherTabs} studentIds
   */
  openTab(url, studentIds, blockOtherTabs = false) {
    this.gateway.send({
      studentIds,
      action: ACTION_OPEN_TAB,
      url,
      blockOtherTabs,
    })
  }

  /**
   * @param {string} studentId
   * @param {number} tabId
   */
  closeTab(studentId, tabId) {
    this.gateway.send({
      studentIds: [studentId],
      action: ACTION_CLOSE_TAB,
      tabId,
    })
  }

  /**
   * @param {string} studentId
   * @param {number} tabId
   */
  activateTab(studentId, tabId) {
    this.gateway.send({
      studentIds: [studentId],
      action: ACTION_ACTIVATE_TAB,
      tabId,
    })
  }

  /**
   * @param {string[]} studentIds
   */
  subscribe(studentIds) {
    this.gateway.send({
      action: ACTION_SUBSCRIBE,
      studentIds,
    })
  }

  /**
   * @param {string[]} studentIds
   */
  blockAllTabs(studentIds) {
    this.gateway.send({
      action: ACTION_BLOCK_ALL_TABS,
      studentIds,
    })
  }

  /**
   * @param {string[]} studentIds
   */
  releaseBlockedTabs(studentIds) {
    this.gateway.send({
      action: ACTION_RELEASE_ALL_BLOCKED_TABS,
      studentIds,
    })
  }

  alertOffline() {
    this.gateway.send({
      action: ACTION_ALERT_TEACHER_OFFLINE,
      allSubscribedStudents: true,
    })
  }

  /**
   * @param {string[]} studentIds
   * @param {string} message
   * @returns Promise<Response>
   */
  sendMessage(studentIds, message) {
    return this.rest.sendMessage(studentIds, message)
  }

  /**
   * @param {string} screenshotUrl
   * @returns Promise<Response>
   */
  getScreenshot(screenshotUrl) {
    return this.rest.getScreenshot(screenshotUrl)
  }
}
