import { AddRounded as IconAddRounded, ClearRounded as IconClearRounded } from '@mui/icons-material'
import { Box, Button, CircularProgress, Collapse, Fade, IconButton, List, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { TransitionGroup } from 'react-transition-group'
import useCurrentUserIsStudentSelector from '../../api/selectors/userRoleSelectors/useCurrentUserIsStudentSelector'
import { ROUTE_TASKS_ADD } from '../../constants'
import useTasks from '../../hooks/tasks/useTasks'
import { Clipboard } from '../../icons/groups'
import BoxContent from '../../ui-components/BoxContent/BoxContent'
import BoxFeedback from '../../ui-components/BoxFeedback/BoxFeedback'
import BoxScrollable from '../../ui-components/BoxScrollable/BoxScrollable'
import firstSchoolDayOfWeek from '../../utils/dateTime/firstSchoolDayOfWeek'
import lastSchoolDayOfWeek from '../../utils/dateTime/lastSchoolDayOfWeek'
import useToggleRightDrawer from '../AppLayout/useToggleRightDrawer'
import Task from './Task'
import TasksHeader from './TasksHeader'

const TasksList = ({ firstDay, lastDay }) => {
  const { isLoading, isSuccess, tasks } = useTasks(firstDay, lastDay)
  const navigate = useNavigate()
  const { handleClose } = useToggleRightDrawer()
  const currentUserIsStudent = useCurrentUserIsStudentSelector()

  const onClickCreateTask = useCallback(() => {
    navigate(ROUTE_TASKS_ADD)
    handleClose()
  }, [handleClose, navigate])

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
        <CircularProgress title="Bezig met laden" size={24} />
      </Box>
    )
  }

  if (isSuccess) {
    if (tasks?.length) {
      return (
        <Fade in>
          <List>
            <TransitionGroup>
              {tasks?.map((task) => (
                <Collapse key={task.id}>
                  <Task task={task} />
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </Fade>
      )
    }

    return (
      <BoxFeedback
        icon={<Clipboard />}
        title="Geen taken"
        description="Er zijn geen taken deze week!"
        action={
          !currentUserIsStudent ? (
            <Button onClick={onClickCreateTask} startIcon={<IconAddRounded />} data-analytics="createTaskFromSidebar">
              Taak toevoegen
            </Button>
          ) : null
        }
        centered
      />
    )
  }

  return null
}
TasksList.propTypes = {
  firstDay: PropTypes.object,
  lastDay: PropTypes.object,
}

const Tasks = () => {
  const { handleToggle } = useToggleRightDrawer()
  const [referenceDate, setReferenceDate] = useState(new Date())
  const { firstDay, lastDay } = useMemo(() => {
    const firstDay = firstSchoolDayOfWeek(referenceDate)
    const lastDay = lastSchoolDayOfWeek(referenceDate)

    return {
      firstDay,
      lastDay,
    }
  }, [referenceDate])

  return (
    <>
      <BoxContent>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h3">Taken</Typography>
          <Tooltip title="Sluit zijbalk taken">
            <IconButton size="small" aria-label="Zijbalk taken" onClick={handleToggle}>
              <IconClearRounded color="disabled" />
            </IconButton>
          </Tooltip>
        </Box>
        <TasksHeader firstDay={firstDay} lastDay={lastDay} setReferenceDate={setReferenceDate} />
      </BoxContent>
      <BoxScrollable>
        <Box
          sx={(theme) => ({
            paddingLeft: `clamp(${theme.spacing(1)}, ${theme?.custom?.app.scaleFactor.default}, ${theme.spacing(2)})`,
            paddingRight: `clamp(${theme.spacing(1)}, ${theme?.custom?.app.scaleFactor.default}, ${theme.spacing(2)})`,
          })}
        >
          <TasksList firstDay={firstDay} lastDay={lastDay} />
        </Box>
      </BoxScrollable>
    </>
  )
}

export default Tasks
