import { setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import { useMemo } from 'react'
import { GROUP_TYPE_CLASS, QUERY_SCHOOL_GROUPS } from '../../../constants'
import request from '../../request'
import useCurrentUserIdSelector from '../../selectors/useCurrentUserIdSelector'
import useCurrentUserInstitutionIdSelector from '../../selectors/useCurrentUserInstitutionIdSelector'
import useQueryData from '../../useQueryData'

const useCurrentUserInstitutionGroups = (institutionId) => {
  const currentUserId = useCurrentUserIdSelector()
  const params = {
    institution: institutionId,
  }

  return useQueryData({
    queryKey: [QUERY_SCHOOL_GROUPS, params],
    queryFn: () => request({ endpoint: `/users/${currentUserId}/groups`, params }),
    queryOptions: {
      enabled: !!currentUserId,
    },
    onSuccess: (data) =>
      setLocalStorage(
        `zc#.${currentUserId}.${institutionId}.classes`,
        data?.filter((group) => group.type === GROUP_TYPE_CLASS)?.length
      ),
  })
}

export const useCurrentUserGroupsByTypeSelector = (types = []) => {
  const institutionId = useCurrentUserInstitutionIdSelector()
  const { data } = useCurrentUserInstitutionGroups(institutionId)

  return useMemo(
    () =>
      data?.reduce((groups, group) => {
        if (types.includes(group.type)) {
          groups.push(group)
        }
        return groups
      }, []),
    [data, types]
  )
}

export default useCurrentUserInstitutionGroups
