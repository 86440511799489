import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { QUERY_CURRENT_USER_TASKS } from '../../../constants'
import request from '../../request'
import useCurrentUserIdSelector from '../../selectors/useCurrentUserIdSelector'
import useQueryData from '../../useQueryData'

const useCurrentUserTasks = (firstDay, lastDay) => {
  const currentUserId = useCurrentUserIdSelector()

  const params = useMemo(
    () => ({
      amount: 100,
      startsAt: formatISO(firstDay),
      endsAt: formatISO(lastDay),
      ordered: 'open',
    }),
    [firstDay, lastDay]
  )

  return useQueryData({
    queryKey: [QUERY_CURRENT_USER_TASKS, params.startsAt, params.endsAt],
    queryFn: () => request({ endpoint: `/users/${currentUserId}/tasks`, params }),
    queryOptions: {
      enabled: !!currentUserId,
      staleTime: 0,
    },
  })
}

export default useCurrentUserTasks
