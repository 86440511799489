import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './components/App/App'
import store from './redux/groups/store'

// We should acknowledge designers of the icons being used in our software (ZULU-5136)
// eslint-disable-next-line no-console
console.log('Icons made by https://www.freepik.com from https://www.flaticon.com')

if (process.env.SENTRY_DSN && process.env.SENTRY_ENV) {
  const isProduction = process.env.SENTRY_ENV === 'production'

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    sampleRate: isProduction ? 0.1 : 1.0,
    tracesSampleRate: isProduction ? 0.1 : 1.0,
    environment: process.env.SENTRY_ENV,
    release: process.env.VERSION,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>
)
