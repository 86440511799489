import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { LAYOUT_MAIN, MODULE_DASHBOARD } from '../constants'
import { useAuthContext } from '../context'
import BareLayout from '../layouts/BareLayout'
import { TYPE_ROUTE_HANDLER } from '../types'
import RequireAuth from './RequireAuth'

const RoutesHandler = ({
  module: authByModule = MODULE_DASHBOARD,
  roles: authByRoles = null,
  protectedRoutes = false,
  layout = LAYOUT_MAIN,
}) => {
  const { isAuthenticated, postAuthNavigateTo } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (protectedRoutes) {
      return
    }

    if (isAuthenticated) {
      return navigate(postAuthNavigateTo)
    }
  }, [isAuthenticated, navigate, protectedRoutes, postAuthNavigateTo])

  if (protectedRoutes) {
    return <RequireAuth module={authByModule} roles={authByRoles} layout={layout} />
  }

  return <BareLayout />
}

RoutesHandler.propTypes = TYPE_ROUTE_HANDLER

export default RoutesHandler
