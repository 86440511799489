import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router'
import { clearOnlineStudents } from '../../redux/groups/onlineStudentReducer'
import useAlertTeacherOffline from './useAlertTeacherOffline'
import useBlockedTabs from './useBlockedTabs'
import useSendScreenshots from './useSendScreenshots'
import useShowIndicatorGroup from './useShowIndicatorGroup'

const RemoteViewResetter = () => {
  const alertTeacherOffline = useAlertTeacherOffline()
  const { resetBlockedTabs } = useBlockedTabs()
  const { resetSendScreenshots } = useSendScreenshots()
  const { resetShowIndicatorGroup } = useShowIndicatorGroup()
  const dispatch = useDispatch()

  // alert students on unmount
  useEffect(
    () => () => {
      alertTeacherOffline()
      resetBlockedTabs()
      resetSendScreenshots()
      resetShowIndicatorGroup()
      dispatch(clearOnlineStudents())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // render page
  return <Outlet />
}

export default RemoteViewResetter
