import { QueryClient } from '@tanstack/react-query'
import { GC_TIME, STALE_TIME } from '../constants/app/misc'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME, // How long data is considered fresh before going stale and refetched when route is visited.
      gcTime: GC_TIME, // How long data remains in memory before garbage collected to free up memory.
    },
  },
})
